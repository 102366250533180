.home {
  position: relative;
  height: max-content;
  overflow: hidden;
  * {
    max-width: 100%;
  }
  .content--wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    background-color: var(--navy);
    .skills {
      .grid {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 50px;
        .skills-list {
          display: grid;
          grid-template-columns: repeat(2, minmax(140px, 200px));
          gap: 0px 10px;
          padding: 0px;
          margin: 20px 0px 0px;
          list-style: none;
        }
        .profile-image {
          position: relative;
          margin: 1.2rem 15px 15px 0;
          &::before {
            position: absolute;
            content: "";
            height: calc(100% - 4px);
            width: calc(100% - 4px);
            top: 15px;
            left: 15px;
            border: 2px solid var(--green);
            // border-radius: 10px;
            transition: var(--transition);
          }
          &:hover::before {
            top: 10px;
            left: 10px;
          }
        }
      }
    }
    .experience {
      .section {
        margin-top: 5rem;
      }
    }
    .projects {
      .section {
        margin-top: 5rem;
      }
    }
    .contact {
      .contact--grid {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        gap: 1rem;
        .contact--content {
          border-left: 2px solid var(--lightest-navy);
          height: calc(100% - 2px);
          padding-left: 1rem;
          .map--wrapper {
            position: relative;
            height: 100%;
            &::before {
              position: absolute;
              content: "";
              height: calc(100% - 4px);
              width: calc(100% - 4px);
              top: 15px;
              left: 15px;
              border: 2px solid var(--green);
              // border-radius: 10px;
              transition: var(--transition);
            }
            &:hover::before {
              top: 10px;
              left: 10px;
            }
          }
        }
      }
    }
  }
}
