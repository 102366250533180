.resume {
  position: relative;
  height: max-content;
  .section {
    margin-top: 5rem;
  }
  ul.education-list {
    list-style: none;
    > li {
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
    h3 {
      font-size: 1.3rem;
    }
    h4 {
      margin-top: 0.2rem;
      font-size: 1.1rem;
      color: var(--green);
    }
    p:not(:last-child) {
      margin: 0.6rem 0 0.8rem;
      font-family: var(--font-mono);
      font-size: 0.9rem;
    }
  }
  ul.experience-list {
    ol {
      list-style: none;
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
      > li {
        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }
        &:not(:first-child) {
          padding-left: 30px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            left: 0px;
            top: -2rem;
            height: 2.75rem;
            width: 20px;
            border: 2px solid var(--lightest-navy);
            border-right: none;
            border-top: none;
            border-bottom-left-radius: 10px;
          }
        }
      }
    }
    h3 {
      font-size: 1.5rem;
      span {
        color: var(--green);
        margin-left: 0.4em;
      }
    }
    p {
      margin-top: 0.6rem;
      font-family: var(--font-mono);
      font-size: 0.9rem;
    }
    ul {
      margin-top: 1.5rem;
      li {
        font-family: var(--font-sans);
        font-size: 1rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
