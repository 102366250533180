.navbar {
  position: fixed;
  top: calc(var(--page-border) + 10px);
  left: calc(var(--page-border) + 10px);
  z-index: 500;
  width: calc(100% - ((var(--page-border) + 10px) * 2));
  header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .logo__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      .logo__sugarloaf {
        position: relative;
        cursor: pointer;
        width: 80px;
        height: 80px;
        .logo,
        .shade {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
          z-index: 1;
          fill: none;
        }
        .logo {
          stroke: var(--green);
          polygon {
            stroke-width: 5px;
          }
          path {
            stroke-width: 1.5px;
          }
        }
        .shade {
          left: -1px;
          top: 5px;
          z-index: 0;
          fill: var(--dark-slate);
          transition: var(--transition);
          backface-visibility: hidden;
        }
        &:hover .shade {
          top: -5px;
          left: -2px;
        }
      }
      .logo--textContainer {
        padding-left: 24px;
        position: relative;
        .spanWrapper {
          margin-top: 0.2rem;
          height: 0.8rem;
          overflow: hidden;
        }
        span,
        a {
          font-size: 0.8rem;
          letter-spacing: 2.5px;
          text-transform: uppercase;
          text-decoration: none;
          color: var(--light-slate);
          cursor: pointer;
          transition: var(--transition);
          display: block;
          font-family: var(--font-mono);
          &:hover {
            color: var(--green);
          }
          display: block;
        }
        &.close {
          span:first-child {
            margin-top: -1rem;
          }
        }
        &::before {
          content: "";
          height: 30px;
          width: 1px;
          background: var(--light-slate);
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
        }
      }
      &.right {
        .logo__sugarloaf {
          padding-right: 6px;
          width: 60px;
          height: 60px;
          order: 2;
          .shade {
            left: 3px;
            top: 8px;
            z-index: 0;
            fill: var(--dark-slate);
            transition: var(--transition);
            backface-visibility: hidden;
          }
          &:hover .shade {
            top: 0px;
            left: 0px;
          }
        }
        .logo--textContainer {
          padding-left: 0px;
          padding-right: 28px;
          &::before {
            // display: none;
            right: 12px;
            left: auto;
          }
        }
      }
    }
  }
  menu {
    position: fixed;
    margin: var(--page-border);
    // height: calc(100vh - 40px);
    height: 0;
    width: calc(100vw - (var(--page-border) * 2));
    top: 0;
    left: 0;
    background-color: var(--dark-slate);
    transition: var(--transition);
    overflow: hidden;
    &.open {
      height: calc(100vh - 40px);
    }
    &.close {
      height: 0;
    }
    > div {
      padding: 40px;
      padding-top: 150px;
      overflow: auto;
      height: 100%;
      .socials {
        a {
          display: inline-block;
          text-decoration: none;
          text-decoration-skip-ink: auto;
          color: inherit;
          position: relative;
          transition: var(--transition);
          padding: 10px;
          font-family: var(--font-mono);
          &:hover {
            color: var(--green);
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
        ul {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin: 0px;
          padding: 0px;
          list-style: none;
          color: var(--light-slate);
          &::before {
            content: "";
            display: block;
            width: 10vw;
            height: 3px;
            margin-right: 30px;
            background-color: var(--green);
          }
        }
      }
      .navbar__menu--item {
        margin: 50px 0;
        h1 {
          font-family: var(--font-header);
          font-size: 5rem;
          letter-spacing: 2px;
          color: var(--light-slate);
          &:hover {
            color: var(--white);
          }
          display: inline-flex;
          min-width: calc(5rem * 6);
          a {
            color: inherit;
            text-decoration: none;
          }
        }
        p {
          display: inline-flex;
          font-size: 1.2rem;
        }
      }
    }
  }
}
