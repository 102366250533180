form.contact {
  .card--grid {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    position: relative;
  }

  input,
  textarea {
    padding: 10px 10px;
    width: 100%;
    border: 2px solid var(--dark-slate);
    outline: 0;
    font-size: 1.1rem;
    background-color: var(--navy);
    caret-color: var(--green);
    color: var(--lightest-slate);
    font-family: var(--font-sans);
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  input.focus,
  textarea.focus {
    border-color: var(--green);
  }
  textarea {
    resize: vertical;
  }
  textarea#subject {
    min-height: 50.67px;
    height: 50.67px;
    margin-bottom: 1rem;
  }
  textarea#message {
    min-height: 150px;
  }
  .input--div {
    position: relative;
    width: 100%;
  }
  label {
    position: absolute;
    font-size: 1.1rem;
    top: 10px;
    left: 10px;
    transition: all 250ms ease-in-out;
    font-family: var(--font-mono);
    color: var(--dark-slate);
  }
  input.focus ~ label,
  textarea.focus ~ label {
    font-size: medium;
    top: -8px;
    color: var(--green);
    background-color: var(--navy);
    padding: 0 2px;
    z-index: 0;
  }
  input.semi-focus ~ label,
  textarea.semi-focus ~ label {
    font-size: medium;
    top: -8px;
    background-color: var(--navy);
    padding: 0 2px;
    z-index: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--navy) inset !important;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill {
    -webkit-text-fill-color: var(--lightest-slate) !important;
  }
  @media screen and (max-width: 600px) {
    .card--grid {
      margin: 30px 0;
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
