.snackbar {
  position: fixed;
  top: 60px;
  left: 50%;
  z-index: 300;
  transform: translateX(-50%);
  background-color: var(--slate);
  color: var(--white);
  padding: 10px;
  border: 2px solid var(--green);
}
