.hero--wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  .hero--container {
    position: fixed;
    padding: 0 150px;
    @media (max-width: 1080px) {
      padding: 0 100px;
    }
    @media (max-width: 768px) {
      padding: 0 50px;
    }
    @media (max-width: 480px) {
      padding: 0 25px;
    }
    min-height: 100vh;
    width: 100%;
    section {
      padding: 0;
    }
    .hero {
      height: max-content;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      flex-direction: column;
      align-items: flex-start;
      min-height: 100vh;
      padding: 0px;
      h1 {
        color: var(--lightest-slate);
        font-weight: 400;
        font-size: 5rem;
        line-height: 5rem;
        font-family: var(--font-header);
        width: max-content;
        &.hasBefore::before {
          content: "<h1>";
          margin-top: -2.5rem;
          left: -2rem;
        }
        &.hasAfter::after {
          content: "</h1>";
          position: relative;
          right: -0.5rem;
          bottom: 0rem;
        }
        > span:not(.noBounce) {
          display: inline-block;
          transition: all 0.3s ease-in-out;
          transition-property: color, transform;
          &.empty {
            width: 1.2rem;
          }
          &:hover {
            color: var(--green);
            animation: effect linear 0.5s;
            -webkit-animation: effect 0.5s;
            -moz-animation: effect linear 0.5s;
            -o-animation: effect linear 0.5s;
          }
        }
        > span.a {
          position: relative;
          color: var(--green);
          z-index: 1;
          margin-right: 0.4rem;
          span {
            position: absolute;
            left: 0.1em;
            top: 0.18em;
            z-index: -1;
            color: var(--lightest-navy);
          }
        }
      }
      h2 {
        .outline {
          color: var(--slate);
          -webkit-text-stroke-width: 1.5px;
          -webkit-text-stroke-color: var(--slate);
          -webkit-text-fill-color: transparent;
          transition: -webkit-text-fill-color 0.2s ease-in-out;
          cursor: pointer;
          &:hover {
            -webkit-text-fill-color: var(--slate);
            -webkit-text-stroke-color: transparent;
          }
        }
      }
      .button-wrapper {
        margin-top: 2rem;
      }

      @keyframes effect {
        0% {
          transform: scale(1, 1);
          -webkit-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -o-transform: scale(1, 1);
        }
        25% {
          transform: scale(1.3, 0.6);
          -webkit-transform: scale(1.3, 0.6);
          -moz-transform: scale(1.3, 0.6);
          -o-transform: scale(1.3, 0.6);
        }

        50% {
          transform: scale(1.1, 0.9);
          -webkit-transform: scale(1.1, 0.9);
          -moz-transform: scale(1.1, 0.9);
          -o-transform: scale(1.1, 0.9);
        }
        100% {
          transform: scale(1, 1);
          -webkit-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -o-transform: scale(1, 1);
        }
      }
    }
    .center__ChevronElement {
      position: fixed;
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      .chevron {
        position: absolute;
        width: 28px;
        height: 8px;
        opacity: 0;
        top: -66px;
        left: 26px;
        transform: scale3d(0.5, 0.5, 0.5);
        animation: move 3s ease-out infinite;

        &:first-child {
          animation: move 3s ease-out 1s infinite;
        }

        &:nth-child(2) {
          animation: move 3s ease-out 2s infinite;
        }

        &:before,
        &:after {
          content: " ";
          position: absolute;
          top: 0;
          height: 100%;
          width: 51%;
          background: var(--dark-slate);
        }

        &:before {
          left: 0;
          transform: skew(0deg, 30deg);
        }

        &:after {
          right: 0;
          width: 50%;
          transform: skew(0deg, -30deg);
        }

        @keyframes move {
          25% {
            opacity: 1;
          }
          33% {
            opacity: 1;
            transform: translateY(30px);
          }
          67% {
            opacity: 1;
            transform: translateY(40px);
          }
          100% {
            opacity: 0;
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
          }
        }
      }
    }
  }
}
