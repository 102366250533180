.button {
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin-top: 2rem;
  cursor: pointer;

  .bottom {
    position: absolute;
    left: 7px;
    top: 7px;
    width: 100%;
    height: 100%;
    background-color: var(--lightest-navy);
    display: block;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
  }

  .top {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1.2rem 4rem calc(1.2rem + 2px);
    border: 2px solid var(--green);
  }

  .button-dark .top {
    border: 2px solid var(--white);
  }

  .top .label {
    font-family: var(--font-mono);
    font-weight: 600;
    color: var(--green);
    font-size: 1rem;
    line-height: 110%;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    width: max-content;
  }

  .button-dark .top .label {
    color: var(--white);
  }

  &:hover .bottom {
    left: 0;
    top: 0;
    background-color: var(--lightest-slate);
  }

  &:hover .top .label {
    color: var(--lightest-navy);
  }

  .button-border {
    position: absolute;
    background-color: var(--lightest-navy);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  &:hover .top .button-border-left,
  &:hover .top .button-border-right {
    height: calc(100% + 2px);
  }

  &:hover .top .button-border-top,
  &:hover .top .button-border-bottom {
    width: calc(100% + 2px);
  }

  .button-border-left {
    left: -2px;
    bottom: -2px;
    width: 2px;
    height: 0;
  }

  .button-border-top {
    left: -2px;
    top: -2px;
    width: 0;
    height: 2px;
  }

  .button-border-right {
    right: -2px;
    top: -2px;
    width: 2px;
    height: 0;
  }

  .button-border-bottom {
    right: -2px;
    bottom: -2px;
    width: 0;
    height: 2px;
  }
}
